.nhs-login-btn::after {
  position: absolute; width: 0; height: 0; overflow: hidden; z-index: -1;
  content: url(../../../assets/svgs/nhs-login-focused.svg) url(../../../assets/svgs/nhs-login-hovered.svg)
}
.enable{
  pointer-events: all;
  cursor: pointer;
}
.disable{
  pointer-events: none;
  cursor: help;
}
.btnContainer{
  width: 326px;
  margin: auto;
  @media (max-width: 767.98px){
    width: 100%;
    margin: 0;
  }
}