.nhs-login-btn::after {
  position: absolute; width: 0; height: 0; overflow: hidden; z-index: -1;
  content: url(../../../../assets/svgs/nhs-login-focused.svg) url(../../../../assets/svgs/nhs-login-hovered.svg)
}

.checkboxContainer{
  margin: auto;
  max-width:326px;
  text-align: start;

  @media (max-width: 767.98px){
    width: 100%;
    height: 50px;
  }

}

.checkboxContainer_error{

    border: rgba(227, 119, 119, 1) 1px solid !important;
    background-color:rgba(227, 119, 119, 0.05);
    /* .blink { */
      animation: blinker 1s infinite;
    /* } */
    
    @keyframes blinker {
        from { opacity: 1.0; }
        50% { opacity: 0.5; }
        to { opacity: 1.0; }
    }
    margin: auto ;
    margin-bottom: 6px !important;
    max-width:326px;
    text-align: start;
    padding:1px;
  
    @media (max-width: 767.98px){
      width: 100%;
      height: 50px;
    }
    .error{
      border-color: #EB5757;
    }
}