
  input::placeholder{
    background-color:#FBFBFD;
    color: #97AAB6;
  }

  .pac-target-input input{
    border: 1px solid #8771DF;
  }

  
  input#googleAutosuggest {
    height: 40px;
    padding-left: 8px;
    color: #212452;
    font-size: 15px;
    width: 100%;
    margin: 3px 0px 3px 0px; 

    /* border: 1px solid #E2E5ED; */
    border: 1px solid #E2E5ED;
    background-color:"#FBFBFD";
  }